:root {
    --ia-app-pale-backgroud-color: rgba(153, 204, 51, 0.12);
}

.iao-node-hovered {
    background-color: var(--ia-app-pale-backgroud-color) !important;
    border-top: 1px solid var(--ia-app-hi-color);
    border-bottom: 1px solid var(--ia-app-hi-color);
    border-right: 1px solid var(--ia-app-hi-color);
}

.iao-after-active-item {
    content: '';
    position: absolute;
    left: -2em;
    top: -1px;
    width: 2em;
    height: calc(100% + 2px);
    border-left: 1px solid var(--ia-app-hi-color);
    border-top: 1px solid var(--ia-app-hi-color);
    border-bottom: 1px solid var(--ia-app-hi-color);
    background-color: var(--ia-app-pale-backgroud-color);
}

.ia-app {
    &.auth .child-page {
        .authContent {
            display: block;
        }

        .simpleCentral,
        .bannerCentral {
            max-width: 100%;
            width: auto;
            margin-left: 1px;
            margin-right: 1px;
        }

        .bannerCentral {
            > .pull-right {
                display: none;
            }
        }

        .all-available-height {
            height: 90%;
            height: calc(
                100vh - 40px - 90px - 2px
            ); /* Top toolbar = 40px, banner = 89/90px, 2px = wriggle room for differing browsers */
            /*background-color: #dcdcdc;*/
            &.row {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .all-available-height-min {
            min-height: 90%;
            min-height: calc(100vh - 40px - 90px - 2px);
            height: auto;
            /*background-color: #ffffff;*/
        }

        .all-available-height .col {
            /*background-color: #ffffff;*/
            height: 100%;

            &:empty {
                height: 1px;
            }
        }

        .iao-wave {
            .ia-name {
                min-width: 48px;
                min-height: 48px;
                width: 48px;
                height: 48px;
                display: none;
            }

            .app-name .product-name {
                /*font-size: 0.4em;*/
            }

            .app-name .builder-name {
                transition: font-size 0.3s;
                /*font-size: 1.2em;*/
            }
        }

        .explorerTree {
            padding-left: 10px;

            > .data-catalog-tree-root {
                &::before {
                    display: none;
                }

                & > .i-name {
                    .group-tree-hint {
                        display: block;
                        padding-left: 29px;
                        font-size: 0.9em;
                        font-style: italic;
                        margin-bottom: 10px;
                    }

                    & ~ ul > li:first-child::before {
                        height: 2em;
                        top: -1em;
                        z-index: 2000;
                        border-left: 1px solid #bbb;
                        left: -1px;
                    }

                    .group-label {
                        font-weight: bold;
                        display: block;
                        float: right;
                        width: calc(100% - 1.7em - 20px);
                        margin: 0 20px 10px 0;
                    }
                }

                .btn-group {
                    & > .btn + .dropdown-toggle {
                        padding-left: 3px;
                        padding-right: 3px;
                        border-left: 1px solid #bbb;
                    }

                    .dropdown-toggle ~ .dropdown-menu {
                        background-color: buttonface;
                        font-size: 13px;
                        border-radius: 2px;
                    }
                }

                li.ind {
                    > .ind-controls {
                        position: absolute;
                        top: 3px;
                        right: 5px;
                    }

                    & > .i-name {
                        margin-right: 60px;
                        display: inline-block;
                        word-break: normal;
                        min-height: 1.8em;
                    }

                    .ia-layers-icon {
                        > i:nth-of-type(1) {
                            color: #000;
                        }

                        > i:nth-of-type(2) {
                            color: var(--ia-app-hi-color);
                        }

                        > i:nth-of-type(3) {
                            color: #333;
                        }
                    }
                }
            }
        }

        .tab-content {
            .explorerTree {
                padding-top: 1em;
            }
        }

        .flex-box {
            display: flex;
            flex-direction: column;
            padding-top: 10px;
            overflow: hidden;

            > ul.nav-tabs {
                flex: 0 0 auto; /* Fixed at their natural height */
            }

            .tab-content .tab-pane {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
            }

            .tab-content {
                flex: 1 1 100%;
                height: 100%;

                .tab-pane.active {
                    display: flex;
                    flex-direction: column;
                }
            }

            .iao-data-catalog-container,
            .explorer-tree-panel,
            .map-controls-panel {
                flex: 1 1 100%;
                display: flex;
                flex-direction: column-reverse;

                .row {
                    flex: 1 1 auto;
                }

                .explorer-tree-holder {
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 100%;
                    margin-top: 0;

                    > div:first-child {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        max-height: 100%;
                    }

                    .explorerTree {
                        flex: 1 0 100%;
                        height: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        .flex-box .nav-tabs {
            padding-left: 10%;
        }

        .flex-box .nav-tabs li {
            width: 40%;
            text-align: center;
        }

        .map-legend-panel,
        .map-controls-panel {
            padding: 10px;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .map-controls-panel .list-group-item {
            padding-left: 30px;
        }

        map-layer-toggle-control ~ .esriLegendLayer {
            font-size: 0.85em;
        }

        .map-layer-toggle-control:disabled,
        .map-layer-toggle-control:disabled ~ .control-label {
            font-size: 0.85em;
        }

        .map-layer-toggle-control:disabled ~ .esriLegendLayer {
            display: none;
        }

        .builder-name {
            .map-name {
                color: var(--ia-app-hi-color);
                position: absolute;
                right: 100%;
                transition: all 0.3s;
            }

            .map-name:not(:empty) {
                right: 0;
            }

            .customer-name:not(:empty)::before {
                content: '| ';
            }
        }

        .map-message-panel,
        .map-cover-panel {
            position: absolute;
            box-sizing: border-box;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            padding: 0;
            opacity: 0;
            background-color: var(--ia-app-hi-color);
            color: #ffffff;
            font-size: 13px;
            transition: all 0.5s;
            overflow: hidden;
            z-index: 999;
        }

        .map-message-panel.in {
            opacity: 0.75;
            padding: 5px;
            height: 28px;
        }

        .map-cover-panel {
            font-size: 1rem;
            border-top: none;
            border-right: 3px solid var(--ia-app-hi-color);
            border-left: 3px solid var(--ia-app-hi-color);
            border-bottom: 3px solid var(--ia-app-hi-color);
            border-radius: 0 2px 2px 0;
            background-color: #343434;
        }

        .map-cover-panel.in {
            opacity: 1;
            padding: 15px;
            height: 100%;
        }

        .cover-page {
            background-color: rgba(255, 255, 255, 0.99);
            color: #333;
            padding: 10px;
            overflow-y: auto;
            width: 100%;
            height: 100%;
            border-radius: 2px;
        }

        .expando > li {
            margin-bottom: 2em;
        }

        .fix-btm-right {
            position: absolute;
            z-index: 1000;
            right: 5px;
            bottom: 5px;
        }

        .snug-top {
            margin-top: 1px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        .map-panel {
            padding-right: 0;
        }

        .popover {
            max-width: 40vw;
            min-width: 40vw;
        }

        .popover-content > .metadata-container {
            height: 25vh;
        }
    }

    &.layout-compact {
        .all-available-height .col {
            &.col-md-1:empty {
                width: 5px;
                height: 1px;
                + .col-md-10 {
                    width: calc(100% - 10px);
                }
            }
        }
    }

    .igroup-name i ~ .igroup-name-text {
        margin-left: 3px;
    }

    label {
        font-weight: var(--ia-app-body-font-weight, 400);
    }

    .catalog-switcher-top {
        position: fixed;
        right: 2vw;
        top: 3px;
        z-index: 10010;

        .dropdown-menu {
            max-height: calc(100vh - 50px);
            overflow-y: auto;
        }

        > div,
        > button,
        > a {
            display: inline-block;
        }

        > .divider {
            margin-right: 1em;
        }

        button,
        .btn,
        .btn-link {
            color: #000;

            &.btn-call-action {
                color: #fff;

                &:hover {
                    color: #000;
                }
            }
        }
    }

    .hover-info {
        ~ .info-box,
        > .info-box {
            display: none;
            width: 0;
            height: 0;
            transition: height 0.3s;
        }

        &:hover,
        &:focus {
            ~ .info-box,
            > .info-box {
                display: block;
                margin-top: 2px;
                padding: 10px 15px;
                font-size: 0.9em;
                background-color: #f1f1f1;
                border-radius: 2px;
                width: auto;
                height: auto;
            }
        }
    }

    .dataset,
    .webItem,
    .web-item {
        .arc-link {
            position: absolute;
            top: 2px;
            right: 2px;
            font-size: 10px;
            opacity: 0;
            transition: all 0.2s;

            .fas {
                min-width: 1.4em;
                min-height: 1em;
            }
        }

        &:hover {
            .arc-link {
                opacity: 0.9;
            }
        }
    }
}
