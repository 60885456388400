@import './PageCommon.scss';

body.full-and-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    overflow: hidden;

    .iaoFooter {
        position: fixed;
        bottom: 0;
        width: 90vw;
        left: 5vw;
        height: 1.1em;
    }
}

.ia-app {
    #authStart.big-btns .btn-holder > div {
        margin: 0px;
        display: inherit;
    }

    #authStart.big-btns .default-btn,
    #authStart.big-btns .default-btn:hover {
        background-color: var(--ia-app-hi-color);
        white-space: normal;
    }

    #authStart.big-btns .btn {
        width: 100%;
        min-height: 75px;
    }

    a:hover > .far {
        font-weight: 900; /* flicks between regular and solid */
    }

    &.auth .big-btns .btn {
        background-color: rgba(45, 45, 45, 1);

        &.btn-primary {
            background-color: var(--ia-app-hi-color);
            color: #333;
        }
    }

    .big-btns {
        .btn,
        .unbtn {
            margin-top: 70px;
            padding: 10px 13px;
            /*> span {
                padding: 8px 13px;
            }*/
        }

        .unbtn {
            color: #fff;
        }

        .btn:focus {
            outline: none;

            > span {
                outline: auto;
            }
        }

        .btn::before,
        .unbtn::before {
            content: '';
            display: block;
            position: absolute;
            height: 60px;
            width: 100%;
            top: -70px;
            left: 0;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto 60px;
            font-family: 'Font Awesome 5 Free', FontAwesome;
            font-size: 45px;
            font-weight: 900;
        }

        .btn:focus::before {
            box-shadow: none;
        }

        .btn-sign-in::before {
            content: '\f2bd';
            font-weight: normal; /* solid vs light */
        }

        .btn-report-builder::before {
            background-image: url(../images/rb4arcgis-logo192.png);
        }

        .btn-dashboard-builder::before {
            background-image: url(../images/2014-Dashboard-Builder-Logo-.jpg);
        }

        .primary-action-link {
            .ia-page-icon {
                color: #777;
                margin-left: 0.25em;
                margin-right: 0.25em;

                > i:last-child {
                    font-size: 0.6em;
                    text-shadow: -3px 0 3px #444;
                    margin-left: -3px;
                    color: #fff;
                }
            }
        }

        .btn-datacatalog {
            &::before {
                display: none;
            }

            > .ia-page-icon {
                position: absolute;
                bottom: 120%;
                left: 50%;
                transform: translate(-50%, 0);
                color: rgba(170, 170, 170, 0.99);
                font-size: 1.4em;
                transition: all 0.2s;

                > i:last-child {
                    font-size: 0.6em;
                    text-shadow: 0px 0 3px #fff;
                    margin-left: -3px;
                    color: #333;
                }
            }

            &:hover,
            &:focus,
            &:focus-within {
                > .ia-page-icon {
                    /*font-size: 1.5em;*/
                    color: var(--ia-app-hi-color-dark);
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
                }
            }
        }

        .btn-webmap-creator::before {
            content: '\f5a0\00a0\f6e3';
        }

        .btn-data-explorer::before {
            content: '\f1c0\00a0\f002';
        }

        .btn-datacatalog-inspector::before {
            content: '\f1c0\00a0\f46c';
        }

        .btn-datacatalog-manager::before {
            content: '\f1c0\00a0\f0ad';
        }

        .btn-datacatalog-creator::before {
            content: '\f1c0\00a0\f067';
        }

        .btn-demographics::before {
            content: '\f182\f1ae\f183';
        }

        .app-desc {
            text-align: justify;
            margin-top: 10px;
            opacity: 0.85;
            transition: opacity 0.1s;

            &.anon-content a {
                color: #bce2f8;
                text-decoration: none;
                border-bottom: 1px dotted #bce2f8;

                &:hover {
                    border-bottom: 1px solid #bce2f8;
                }
            }
        }

        div:hover > .app-desc,
        .primary-action-link > .app-desc {
            opacity: 1;
        }

        .btn-holder:nth-child(2) {
            margin-top: 3em;
        }
    }

    .modal {
        .carousel {
            width: 900px;
            min-width: 900px; /* IE not playing nice */
            min-height: 50vh;
            position: relative;
            overflow: hidden;

            .carousel-items {
                position: absolute;
                transition: all 0.4s;
                display: flex;
                flex-direction: row;
                left: 0;
                top: 0;

                &.offset-1 {
                    left: -100%;
                }

                &.offset-2 {
                    left: -200%;
                }

                &.offset-3 {
                    left: -300%;
                }

                &.offset-4 {
                    left: -400%;
                }

                &.offset-5 {
                    left: -500%;
                }

                &.offset-6 {
                    left: -600%;
                }

                &.offset-7 {
                    left: -700%;
                }

                &.offset-8 {
                    left: -800%;
                }

                .item {
                    width: 900px;
                    min-width: 900px; /* IE not playing nice */
                }
            }

            .ia-welcome-popup-box {
                color: #fff;
                padding: 3em;
                background-color: #333;
                background-image: url(../images/banner-dialog-backdrop2.png);
                background-position: left bottom;
                background-repeat: no-repeat;
                min-height: 50vh;

                a[target='iaHelpWindow'] {
                    &::after {
                        content: '\f35d';
                        font-family: 'Font Awesome 5 Free', FontAwesome;
                        font-weight: bold;
                        margin-left: 2px;
                        font-size: 0.9em;
                    }
                }

                .bouncy-row {
                    font-size: 48px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    padding-bottom: 1em;
                    width: 100%;
                    text-align: center;

                    .as-link {
                        cursor: pointer;
                    }
                }

                > .filler {
                    position: absolute;
                    left: 3em;
                    top: 3em;
                    right: 3em;
                    bottom: 3em;
                    /*font-family: Nunito, Roboto, Verdana, Arial, Helvetica, sans-serif;*/
                    .text-body {
                        width: calc(100% - 170px);
                        min-height: calc(100%);
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 1.7;

                        > h2 {
                            margin-bottom: 20px;
                            color: inherit;
                        }

                        strong {
                            color: #99cc33;
                        }

                        a:not(.btn) {
                            color: #6eb4d9;
                        }
                    }

                    > .bottom-left-btn {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        min-width: 140px;
                        max-width: 140px;
                        text-align: center;

                        > .btn {
                            white-space: normal;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }

    .app-desc {
        form {
            .control-label {
                color: #fff;
            }
        }
    }

    @media screen and (max-width: 999px), screen and (max-height: 1000px) {
        .modal {
            .carousel {
                min-height: 65vh;

                .ia-welcome-popup-box {
                    min-height: 65vh;
                }

                .bouncy-row {
                    font-size: 6vh;
                    position: relative;
                    right: auto;
                    bottom: auto;
                    padding-bottom: 1em;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}
