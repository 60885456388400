.catalog-explorer-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    overflow: hidden;

    .catalog-explorer {
        padding: 5px 0 0 1vw;

        .data-explorer-frame {
            border: 1px solid #fefefe;
            border-radius: 2px;
            width: 100%;
            min-height: calc(100vh - 135px);
            overflow: hidden;

            > iframe,
            > .mask {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: none;
                overflow-y: scroll;
            }

            > .mask {
                z-index: 1001;
                background-color: rgba(255, 255, 255, 0.7);
                padding: 10vh;
                text-align: center;
            }
        }
    }
}
