.progress-message-large {
    > .fa-stack {
        width: 1.4em;
        height: 1em;
        line-height: 1em;

        > .fas, .far, .fab {

            &:first-child {
                color: #99CC33;
            }

            &.fa-spin {
                clip-path: polygon(50% 0, 100% 0%, 100% 50%, 50% 50%);
            }

            &.central {
                color: #fff;
                font-size: .6em;
            }
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .progress-message-large > .fa-stack > .fas:last-child,
    .progress-message-large > .fa-stack > .far:last-child {
        clip: rect(0 .7em .5em 0);
    }
}

@supports (position: -ms-page) {
    .progress-message-large > .fa-stack > .fas:last-child,
    .progress-message-large > .fa-stack > .far:last-child {
        clip: rect(0 .7em .5em 0);
    }
}
