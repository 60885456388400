.instance-level-metadata-table {
    tr {

        td:first-child {
            span {
                font-style: italic;
            }
        }

        &.instance-level {
            td:first-child {
                span {
                    font-style: normal;
                }
            }
        }
    }
}
