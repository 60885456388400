@import './PageCommon.scss';

$iaoVeryLightGreen: rgba(153, 204, 51, 0.12);

.web-map-builder {
    .esri-popup__footer {
        order: 3;
    }

    .esri-popup__header {
        order: 1;
    }

    .esri-popup__content {
        order: 2;
    }

    .all-available-height {
        .flex-box {
            .tab-content {
                border-left: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                border-right: 1px solid #ddd;
                margin-bottom: 5px;

                .col-md-12.geo-selector {
                    > select {
                        border: none;
                        padding-left: 1.8em;
                    }

                    &::after {
                        font-family: 'Font Awesome 5 Free';
                        content: '\f5fd';
                        font-weight: bold;
                        pointer-events: none;
                        position: absolute;
                        left: 25px;
                        top: calc(50% - 0.5em);
                        opacity: 0.6;
                    }
                }

                .arc-layer-list {
                    ul {
                        padding: 0;

                        .list-group-item {
                            border-right: none;
                            border-left: none;
                            border-radius: 0;

                            &:first-child {
                                border-top: none;
                            }

                            &:last-child {
                                border-bottom: none;
                            }

                            .esri-legend__service {
                                margin: 0;
                                padding: 0;
                            }

                            .map-layer-toggle-control ~ .iao-arc-legend {
                                height: 0;
                                overflow: hidden;
                                transition: height 0.3s;
                            }

                            .map-layer-toggle-control:checked:not(:disabled) ~ .iao-arc-legend {
                                height: auto;
                                .esri-legend__layer-caption {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .explorerTree {
        overflow: scroll;

        .widgetIndicators {
            > .igroup-name {
                > a {
                    padding: 2px 0;
                }
            }

            .ind .iao-controls {
                float: right;
                margin-right: 15px;

                > .btn-hover-parent {
                    width: 0;
                    display: none;
                    transition: width 0.1s;
                }
            }

            .ind:hover .iao-controls {
                > .btn-hover-parent {
                    width: auto;
                    display: inline-block;
                }
            }
        }
    }

    .map-masker {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        padding: 12% 0;
        font-size: 12vh;
        display: none;
        cursor: not-allowed;
        text-align: center;

        &.in {
            display: block;
        }
    }
}
