.ia-app {
    .options-content {
        .discrete-toggle {
            input[type="checkbox"] {
                width: 0;
                height: 0;
                display: inline;
            }

            > input:focus ~ label, 
            > label:focus,
            > label:active {
                border: none;
                box-shadow: none;
            }

            .slider-prefix {
                margin-right: 10px;

                ~ .slider-suffix {
                    margin-left: 70px;

                    &::before {
                        display: block;
                        position: absolute;
                        left: -65px;
                        top: .6em;
                        font-family: 'Font Awesome 5 Free';
                        font-weight: bold;
                        content: '\f111';
                        font-size: .9em;
                        width: 50px;
                        max-width: 50px;
                        height: 1.2em;
                        background-color: #dedede;
                        padding: 0;
                        text-align: left;
                        border-radius: 5px;
                        transition: padding .3s;
                        box-sizing: border-box;
                        color: #333 !important;
                        border: 1px solid #fff;
                    }
                }
            }

            input[type="checkbox"] {
                ~ .slider-prefix {
                    font-weight: normal;
                }

                ~ .slider-suffix {
                    font-weight: bold;

                    &::before {
                        padding: 0 0 0 calc(50px - 1.1em);
                    }
                }
            }

            input[type="checkbox"]:checked {
                ~ .slider-prefix {
                    font-weight: bold;
                }

                ~ .slider-suffix {
                    font-weight: normal;

                    &::before {
                        padding: 0 0 0 0;
                    }
                }
            }
        }
    }
}
