﻿.dataset {
    .thumbnail-holder .tile-image, 
    .item-desc {
        display: none;
    }
}

.big-tiles {
    .dataset {
        margin: 1%;
        min-width: 100px;
        width: 31%;
        min-height: 140px;
        height: 220px;
        padding: 0;
        border-radius: 0;

        .thumbnail-image {
            width: 100%;
            border: none;
        }

        .thumbnail-holder .tile-image {
            display: block;
            width: 100%;
            background-repeat: no-repeat;
            background-size: auto;
        }

        .thumbnail-holder .tile-image img {
            width: 100%;
            border: none;
        }

        .iao-icon {
            padding: 30px 0;
            font-size: 60px;
        }

        > .thumbnail-holder {
            height: 140px;
        }

        > .dsName,
        > .datasetActions {
            position: absolute;
            top: auto;
            left: 0;
            margin: 0;
            width: 100%;
            height: auto;
            padding: 5px 5px;
        }

        > .dsName {
            bottom: 2.75em;
            background-color: #fff;
            padding: 10px 5px 10px 5px;
            border-top: 1px solid rgba(0, 0, 0, 0.35);
        }

        > .datasetActions {
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            opacity: 1;
        }

        .iao-icon.fa-stack {
            font-size: 36px;
            margin-top: -5px;
        }
    }

    .datasetActions .link-silent.link-keyword {
        display: inline-block;
        position: relative;
        width: auto;
        height: auto;
        font-size: 13px;
        top: 0;
        left: 2px;
        margin-right: 2px;
    }

    .datasetActions a:hover .link-silent.link-keyword,
    .datasetActions a:focus .link-silent.link-keyword {
        text-decoration: underline;
    }
}

.rows {
    .dataset {
        position: relative;
        float: none;
        box-shadow: none;
        margin: 0;
        padding: 5px 5px 0 5px !important;
        min-width: 100px;
        width: 100%;
        min-height: 35px;
        height: auto;
        display: block;
        z-index: 99;
        border-radius: 2px;
        border: none;
        border-bottom: 1px solid rgba(74, 56, 117, 0.08);
        cursor: default;
        text-align: left;
        overflow: hidden;

        > div {
            display: inline-block;
            position: relative;

            &.item-desc {
                display: block;
                font-size: 0.8em;
                margin: -10px 320px 0 85px;
                min-height: 0.75em;
            }
        }

        > div.selection-box {
            display: block;
            position: absolute;
        }

        > .thumbnail-holder {
            width: 50px !important;
            position: absolute;
            left: 0;
            top: 5px;
        }

        > .dsName {
            width: auto;
            height: auto;
            top: auto;
            left: auto;
            margin: 0 80px;
            text-align: left;
        }

        .iao-icon {
            font-size: 1.6em;
            height: 1.2em !important;
        }

        .iao-icon.fa-stack {
            font-size: 1.1em;
        }

        > .datasetActions {
            display: block;
            position: absolute;
            opacity: 0.71;
            right: 15px;
            left: auto;
            margin: 0;
            bottom: auto;
            top: 8px;

            > a {
                font-size: 20px;
            }
        }

        > .item-modified {
            display: block;
            position: absolute;
            right: 170px;
            left: auto;
            margin: 0;
            bottom: auto;
            top: 15px;
        }
    }

    .azure-shared-item::after {
        top: 20px;
        right: auto;
        left: 45px;
        font-size: 16px;
    }
}
