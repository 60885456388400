.catalog-inspector-page {
    #dsControls .col {
        width: auto;
    }

    #dsControls .col > label {
        margin-left: 18px;
        text-align: left;
        word-break: break-all;
    }

    .server-list li.geo::before {
        content: '\f233';
        transform: none;
    }

    .validation-errors-list {
        li {
            list-style: none;
            /*&::before {
                font-family: 'Font Awesome 5 Free';
                content: '\f00d';
                font-weight: bold;
                position: absolute;
                left: -1.2em;
                top: 0;
                color: #cc2222;
            }*/
            &.http-error-403::before {
                content: '\f05e';
            }
        }
    }

    .validation-results {
        li {
            list-style: none;
            padding-left: 0;
            margin-bottom: 10px;

            &::before {
                content: '';
                font-family: 'Font Awesome 5 Free', FontAwesome;
                margin-right: 10px;
                font-size: 2em;
            }

            &.validation-ok::before {
                content: '\f058';
                color: var(--ia-app-hi-color);
            }

            &.validation-warning {
                &::before {
                    content: '\f071';
                    color: lighten(#cc2222, 20%);
                    font-weight: bold;
                    transform: none;
                }

                ~ .validation-fail,
                + .validation-warning {
                    display: inline-block;
                    margin-left: 15px;
                    &::before {
                        margin-right: 3px;
                    }
                }
                > .generic-list {
                }
            }

            &.validation-heading {
                margin-top: 2em;
                border-top: 1px dashed #ccc;
                z-index: 1;

                &::before {
                    display: none;
                }

                &:first-child {
                    margin-top: 0;
                    border-top: none;
                }
            }

            &.validation-fail {
                &::before {
                    content: '\f057';
                    color: #cc2222;
                }

                &.uncaught-exception {
                    color: #cc2222;
                }
            }

            &.validation-action {
                height: 1px;
                margin: 0;
                z-index: 100;

                > div {
                    position: absolute;
                    top: calc(2em + 20px);
                    right: 0;
                }
            }

            > .geo-list {
                margin-left: 40px;
                margin-top: 10px;
            }

            > .indicator-list,
            > .instance-list,
            > .relationship-list,
            > .theme-list,
            > .generic-list {
                font-size: 0.9em;
                margin-top: 10px;

                > li::before {
                    font-size: 1em;
                }
            }
        }

        .warning-list {
            font-size: 0.9em;

            > li {
                list-style: none;
            }
        }

        &.validation-errors {
            .validation-ok {
                display: none;
            }
        }
    }
}
