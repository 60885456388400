.ia-app {
    @import './styles/_bootstrap.min.scss';
}

@import './styles/defaults.scss';

:root {
    --ia-app-hi-color: #99cc33;
    --ia-app-hi-color-dark: #8cbf26;
    --ia-app-hi-color-light: #bbee55;
    --ia-app-hi-color-reverse: #333;
    --ia-app-hi-color-negative: #fff;
    --ia-app-body-font: Avenir-light, 'Avenir Next', 'Work Sans', work_sans_regular, Poppins, nunitoregular, sans-serif;
    --ia-app-header-font: Avenir-light, 'Avenir Next W01', var(--ia-app-body-font);
    --ia-app-body-font-weight: 400;
    --ia-app-header-font-size: 16px;
}

.ia-app {
    font-family: var(--ia-app-body-font);
    font-weight: var(--ia-app-body-font-weight, 400);
    font-size: var(--ia-app-header-font-size, 15px);

    body {
        line-height: normal;
    }

    @media screen {
        min-height: 100vh;
    }

    body, form, select, button, input, textarea,
    .simpleInfoWindow, /* ArcGIS Online popup window specific */
    .popover, .tooltip, .dropdown-menu > li > a /* bootstrap overrides */ {
        font-family: var(--ia-app-body-font);
        font-weight: var(--ia-app-body-font-weight, 400);
    }

    h1,
    h2,
    h3,
    h4,
    .h1,
    .h2,
    .h3,
    .h4,
    .toolboxTitleBar,
    .big-btns .btn {
        font-family: var(--ia-app-header-font);
    }

    p {
        line-height: 1.3;
    }

    .navbar-brand-icon {
        width: auto;
        height: 1.7em;
    }

    .inline-icon {
        height: 1.1em;
        vertical-align: text-top;
    }

    .app-header {
        &.thinner {
            .banner-deep {
                padding: 1px 25px 5px 25px;

                > .iao-wave {
                    h1 {
                        font-size: 1em;
                    }

                    .ia-name {
                        min-width: 40px;
                        min-height: 40px;
                        width: 40px;
                        height: 40px;
                        display: none;
                    }

                    .app-name {
                        .product-name {
                            font-size: 0.8em;

                            .tm {
                                font-size: 0.75em;
                            }
                        }

                        .builder-name {
                            transition: unset;
                        }
                    }
                }

                > .pull-right {
                    > a {
                        height: 32px;
                        display: block;

                        > img {
                            margin-top: -20px;
                        }
                    }
                }
            }
            /*+ .app-body {
                margin-top: 130px;
            }*/
        }
    }

    .app-header-cogs {
        transform: rotate(-25deg) scale(0.9) translate(-10px, -10px);
        margin-left: 0.25em;
    }

    .banner {
        z-index: 40;
    }

    .bannerCentral {
        background-color: #333;
        border-bottom-color: var(--ia-app-hi-color);
        box-shadow: 0px 0px 1px 1px var(--ia-app-hi-color-dark);
    }

    .navbar-nav {
        .usr-name {
            display: none;
        }
    }

    .warning-disappear-after-15 {
        padding: 10px;
        background-color: #f2dede;
        border-radius: 2px;
        margin-bottom: -10px;
        margin-top: 10px;
        animation: slideUp 0.6s linear 10s 1 forwards;
        box-sizing: border-box;
        overflow: hidden;
    }

    .pure-tip {
        position: relative;

        &::before,
        &::after {
            position: absolute;
            opacity: 0;
            transition: all 0.1s;
            pointer-events: none;
            /* Centred botton or top by default */
            left: 50%;
            width: auto;
            transform: translateX(-50%);
            z-index: 1001;
            transition: opacity 0.4s;
        }

        &::after {
            content: attr(data-tooltip);
            padding: 4px 9px;
            border-radius: 2px;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
            background-color: rgba(17, 17, 17, 0.9);
            color: #fff;
            font-family: var(--ia-app-body-font);
            font-size: 13px;
            font-weight: normal;
            text-align: center;
            line-height: 1.3;
            white-space: normal;
            hyphens: manual;
        }

        &.wider::after {
            min-width: 10em;
        }

        &::before {
            content: '';
            font-family: Consolas, 'Courier New', monospace;
            font-weight: bold;
            width: 16px;
            height: 16px;
            font-size: 15px;
            color: rgba(17, 17, 17, 0.9);
        }

        &:hover::before,
        &:hover::after,
        &:focus::before,
        &:focus::after {
            display: block;
            opacity: 1;
            border: none;
        }

        &:active::before,
        &:active::after {
            border: none;
        }

        &.pure-tip-bottom,
        &.pure-tip-bottom-right,
        &.pure-tip-bottom-left {
            &::before {
                content: '▲'; /*\f0d8';*/
                top: calc(100% - 0.6em);
            }

            &::after {
                top: calc(100% + 5px);
            }
        }

        &.pure-tip-bottom-right {
            &::after {
                transform: translateX(-15px);
            }
        }

        &.pure-tip-bottom-left {
            &::after {
                transform: translateX(-90%);
            }
        }

        &.pure-tip-top {
            &::before {
                content: '▼'; /*'\f0d7';*/
                bottom: calc(101% - 5px);
            }

            &::after {
                bottom: calc(101% + 2px);
            }
        }

        &.pure-tip-right {
            &::before {
                content: '▲'; /*'\f0d7';*/
                left: calc(100% - 4px);
                top: 0;
                transform: rotate(270deg);
                height: auto;
            }

            &::after {
                left: calc(100% + 9px);
                top: 0;
                transform: none;
            }
        }
    }

    .help-online-footer-link {
        margin-top: 5em;
        padding: 5px 10px;
        background-color: #ededed;
        border-radius: 1px;
    }

    .progress-message-large {
        font-size: 1.6em;
    }

    .cb {
        display: block;
        clear: both;
    }

    @keyframes slideUp {
        0% {
            height: 6em;
        }

        99% {
            padding: 10px;
        }

        100% {
            height: 0;
            opacity: 0;
            padding: 0;
        }
    }

    &.skin-dark {
        .bannerCentral {
            background-color: var(--ia-app-hi-color);
            color: #333;
            border-bottom-color: #333;

            h1 {
                color: #333;
            }

            .builder-name .map-name {
                color: #333;
            }

            .iao-wave .ia-name {
                border: 3px solid #444;
                border-radius: 2px;
            }
        }
    }

    &.layout-compact {
        font-size: 14px;

        .navbar {
            min-height: 37px !important;
            max-height: 37px !important;

            .navbar-brand-icon {
                height: 1.5em;
            }
        }

        .banner {
            margin-top: 37px;

            .bannerCentral {
                padding-top: 5px;
                padding-bottom: 5px;

                h1 {
                    font-size: 1.3em;
                }

                > .pull-right {
                    margin-top: -20px;
                }
            }
        }

        .iao-wave {
            .ia-name {
                width: 48px;
                height: 48px;
                min-width: 48px;
                min-height: 48px;
            }

            .app-name .product-name {
                font-size: 0.6em;
            }
        }

        .simple-central {
            width: auto;
            max-width: 100%;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .modal {
        .modal-header {
            background-color: #333;
            padding: 10px 15px;

            .modal-title {
                margin: 0 3em 0 0;
                line-height: 1.4;
                z-index: 0;
            }

            .close {
                z-index: 10;
            }

            &::after {
                /*@extend .ia-app-hub .bannerCentral::after;*/
                background-image: url(./images/Online-Banner-Background.png);
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            .close {
                color: #fff;
            }
        }

        .modal-body-unpadded {
            padding: 0;
        }

        .modal-footer {
            z-index: 100;
        }
    }

    .progress-bar {
        background-color: var(--ia-app-hi-color);
    }

    .modal-body-scrollable {
        width: 100%;
    }

    body.landing,
    html.landing {
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .navbar-nav,
    .catalog-switcher-top {
        button.btn-link {
            /*@extend .ia-app-hub .navbar-nav>li>a;*/
            &:disabled {
                opacity: 0.3;
                cursor: not-allowed;
            }
        }
    }

    &.auth .big-btns {
        .btn-holder {
            > .col-md-4,
            > .col-md-12 {
                padding: 10px;

                > div {
                    background-color: rgba(200, 200, 200, 0.19);
                    border: 1px solid rgba(200, 200, 200, 0.29);
                    padding: 85px 15px 15px 15px;
                    border-radius: 1px;
                    min-height: 18em;

                    .btn {
                        margin-top: 0;

                        &::before {
                            color: rgba(200, 200, 200, 0.99);
                        }

                        &:disabled,
                        &.disabled {
                            cursor: not-allowed;

                            &::after {
                                font-family: 'Font Awesome 5 Free';
                                content: '\f3ed';
                                font-weight: bold;
                                position: absolute;
                                right: calc(50% - 0.7em);
                                top: -0.85em;
                                color: rgba(100, 100, 100, 0.95);
                                background-color: rgba(255, 255, 255, 0.9);
                                border: 1px solid #fff;
                                width: 1.4em;
                                border-radius: 5px;
                                padding: 2px 0;
                            }

                            &.btn-datacatalog::after {
                                content: '\f05e';
                            }
                        }
                    }
                }
            }

            > .col-md-4 {
                min-height: 280px;
            }

            > .col-md-12 {
                > div {
                    min-height: 14em;
                    padding-top: 10px;

                    > div.pull-right {
                        padding-top: 85px;
                        margin: 0 0 20px 50px;
                        z-index: 1001;
                    }

                    > h2 {
                        font-weight: bold;
                    }
                }
            }

            &:nth-child(2) {
                margin-top: 0;
            }
        }
    }

    .app-desc {
        a {
            color: #f60;
        }
    }

    &.anon {
        .auth-content {
            display: none !important;
        }
    }

    &.auth {
        .anon-content {
            display: none !important;
        }
    }

    &.anon,
    &.auth {
        .big-btns .btn-holder > div {
            opacity: 1;
        }
    }

    &.anon {
        .page:not(.catalog-metadata-page) {
            .banner {
                position: fixed;
                left: 0;
                top: 0;

                .bannerCentral {
                    width: 90vw;
                    left: 5vw;
                    min-height: calc(100vh - 70px);
                    height: auto;
                    transition: height 0.3s;
                }
            }

            .app-header + .app-body {
                margin-top: 12.5em;
                margin-bottom: 0;
                color: #fff;
                z-index: 1001;
            }

            @media screen and (min-width: 1200px) {
                .bannerCentral {
                    max-width: none;
                    width: 80vw;
                    left: 10vw;
                }
            }
        }
    }

    .landing {
        .btn-holder .auth-content .btn {
            padding: 20px 20px;
            white-space: normal;
            line-height: 1;
        }

        @media screen and (max-width: 1360px) {
            .btn-holder .auth-content .btn {
                height: calc(2em + 40px);
            }
        }

        @media screen and (max-width: 1260px) {
            &.auth .big-btns .btn-holder > .col-md-4 > div {
                min-height: 21em;
            }
        }
    }

    .dark-fill {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 30px;
        border-radius: 2px;
        height: 400px;
    }

    .icon-topper,
    .pull-down {
        position: absolute;
        width: 100%;
        text-align: center;
        left: 0px;
    }

    .pull-down {
        bottom: 0px;
    }

    .icon-topper {
        top: -15px;

        img {
            display: inline-block;
            border-radius: 1px;
            box-shadow: inset 0px 0px 3px rgba(255, 255, 255, 0.7), 0px 0px 3px rgba(255, 255, 255, 0.7);
        }

        img:first-of-type {
            transform: rotate(-15deg);
        }

        img:last-of-type {
            transform: rotate(10deg);
        }
    }

    .utility .bannerCentral::after {
        width: 120px;
        height: 65px;
        background-size: 70px auto;
        font-size: 7px;
        text-align: center;
    }

    .bannerBackdrop::before {
        background-color: rgba(153, 204, 51, 0.1);
    }

    .stripe {
        background-color: rgba(153, 204, 51, 0.6);
        border-radius: 2px;
    }

    .offset-page {
        margin-top: 100%;
    }

    .leftNav a {
        display: inline-block;
        word-wrap: break-word;
        white-space: normal;
        margin-left: 16px;
    }

    .leftNav a,
    .leftNav li {
        position: relative;
    }

    .leftNav a .fa {
        position: absolute;
        left: -15px;
        top: 4px;
    }

    .col-md-10 h1,
    .col-md-10 h2.underline {
        padding-bottom: 20px;
        /*margin-bottom: 30px;*/
        border-bottom: 1px solid #ddd;
    }

    .bannerCentral h1,
    .bannerCentral h2,
    .bannerCentral h3,
    .bannerCentral h4,
    .bannerCentral h5,
    .bannerCentral h6,
    .tipContent h1,
    .tipContent h2,
    .tipContent h3,
    .tipContent h4,
    .tipContent h5,
    .tipContent h6,
    .bannerCentral .call {
        color: inherit;
    }

    .callButton,
    .bannerCentral .callButton {
        border-color: #6799cc !important;
        color: #374e5e;
    }

    .iao-wave .ia-name {
        background-image: url(./images/InstantAtlas-152px.jpg);
        background-size: 100%;
        box-shadow: none;
        margin-right: 10px;
    }

    .iao-wave .iao-profiles-name {
        /*background-image: url(/static/images/InstantAtlas-Dashboard-Builder-Text.png);*/
    }

    .iao-wave .iao-profiles-name::after {
        display: none;
    }

    .authContent .iao-wave .iao-profiles-name::after,
    .titleContent .iao-wave .iao-profiles-name::after {
        display: none;
    }

    .iao-wave::after {
        height: 1px;
    }

    .big-btns .btn-holder {
        display: block;
    }
    /*.big-btns .btn-holder > div
    {
	    max-width: 35%;
	    width: 30%;	
	    margin-right: 1%;
	    overflow: hidden;
	    font-size: 26px;
    }

    .big-btns .btn
    {
	    display: block;
	    max-width: 100%;
	    min-width: 100%;
	    padding: 10px 5px 10px 5px;
    }*/
    .pageBackdrop img.scale-1 {
        width: 430px;
        right: 575px;
        z-index: 3;
    }

    .pageBackdrop img.scale-2 {
        width: 450px;
        z-index: 3;
        right: 805px;
    }

    .pageBackdrop img.scale-3 {
        width: 850px;
        z-index: 2;
    }

    .sign-in-panel {
        position: absolute;
        width: 100%;
        z-index: 100;
    }

    .sign-in-panel .col-left {
        width: 48%;
        float: left;
        padding-left: 20px;
    }

    .sign-in-panel fieldset::after {
        position: absolute;
        top: 10%;
        left: 50%;
        content: ' ';
        height: 86%;
        border-right: 1px solid #fff;
    }

    .sign-in-panel .col-right {
        width: 48%;
        float: right;
        padding-left: 20px;
    }

    .sign-in-panel fieldset {
        border: 2px solid #f25a2b;
        border-radius: 2px;
        margin: 0px 115px;
        background-color: rgba(103, 153, 204, 0.9);
        padding: 10px;
    }

    .sign-in-panel fieldset legend {
        background-color: #f25a2b;
        border: 2px solid #f25a2b;
        border-radius: 2px;
        color: #fff;
        font-size: 1.2em;
        padding-left: 20px;
        width: 150px;
        margin-bottom: 0px;
    }

    .sign-in-panel fieldset > div > div {
        margin: 15px 0px;
        position: relative;
    }

    .sign-in-panel fieldset label {
        color: #fff;
    }

    .sign-in-panel fieldset input[type='text'],
    .sign-in-panel fieldset input[type='password'] {
        border: 1px solid #ccc;
        border-radius: 2px;
        padding: 4px;
        /* width: 100%; */
    }

    .sign-in-panel .btn {
        display: inline-block;
        min-height: 40px;
        background-color: #f25a2b;
        border-radius: 4px !important;
        color: #eee;
        font-weight: 500;
        box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.3) !important;
        border: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    .sign-in-panel .arc-sign-in {
        background-image: url(//www.arcgis.com/favicon.ico);
        background-repeat: no-repeat;
        background-position: 20px center;
        padding-top: 0.5em;
        padding-left: 60px;
        padding-bottom: 0.5em;
        padding-right: 20px;
    }

    .sign-in-panel .hard-right {
        position: absolute;
        right: 5%;
        bottom: 0.5em;
    }

    input[type='checkbox']:not(.native) + label,
    input[type='radio'] + label,
    .checkbox input[type='checkbox']:not(.native) ~ label,
    .checkbox input[type='radio'] ~ label {
        margin-left: 0px;
    }

    td {
        > input[type='checkbox']:not(.native) + label,
        > input[type='radio'] + label,
        > .checkbox input[type='checkbox']:not(.native) ~ label,
        > .checkbox input[type='radio'] ~ label {
            margin-left: 24px;
        }
    }

    .btn {
        font-weight: 500;
    }

    .btn-primary {
        background-color: var(--ia-app-hi-color);
        border-color: var(--ia-app-hi-color-dark);
        color: #333;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .btn-primary:hover,
    .btn-primary:focus,
    .btn-primary:active,
    .btn-primary.active,
    .open .dropdown-toggle.btn-primary {
        background-color: var(--ia-app-hi-color-dark);
        border-color: var(--ia-app-hi-color);
    }

    .btn-primary.disabled,
    .btn-primary[disabled],
    .btn-primary.disabled:hover,
    .btn-primary[disabled]:hover,
    .btn-primary.disabled:focus,
    .btn-primary[disabled]:focus,
    fieldset[disabled] .btn-primary:focus,
    .btn-primary.disabled:active,
    .btn-primary[disabled]:active,
    fieldset[disabled] .btn-primary:active,
    .btn-primary.disabled.active,
    .btn-primary[disabled].active,
    fieldset[disabled] .btn-primary.active {
        background-color: #ccc;
        border-color: #aaa;
        color: #aaa;
    }

    .icon-sup {
        font-size: 0.4em;
        vertical-align: super;
    }

    .iaoFooter {
        position: relative;
        margin-top: 3em;
    }

    .popover.bottom > .arrow::after {
        border-bottom-color: var(--ia-app-hi-color-dark);
    }

    .popover.top > .arrow::after {
        border-top-color: var(--ia-app-hi-color-dark);
    }

    .popover.left > .arrow::after {
        border-left-color: var(--ia-app-hi-color-dark);
    }

    .popover.right > .arrow::after {
        border-right-color: var(--ia-app-hi-color-dark);
    }

    .popover-title,
    .popover {
        background-color: var(--ia-app-hi-color-dark);
    }

    .popover-title {
        color: #fff;
        font-size: 1.1em;
    }

    .popover-content {
        background-color: #fff;
    }

    .signInLink {
        display: block;
        width: 100%;
    }

    .signInLink .dropdown-menu {
        position: relative;
        right: auto;
        left: auto;
        border-top: 2px solid #fff;
        z-index: -1;
        top: 109%;
        border-radius: 4px;
        overflow: hidden;
        transition: all 0.3s;
        display: block;
        width: 75%;
        margin-left: 12.5%;
        opacity: 1;
        min-height: 15em;
        height: auto;
    }

    .signInLink .dropdown-menu span,
    .signInLink .dropdown-menu label {
        color: #fff;
    }

    .signInLink > .btn {
        border-bottom: none;
        box-sizing: border-box;
        position: relative;
        z-index: 100;
    }

    .signInLink > .dropdown-menu,
    .signInLink > a.btn {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
    /*.signInLink::after,.signInLink::before
    {
	    content: ' ';
	    box-sizing: border-box;
	    display: block;
	    height: 15%;
	    position: absolute;
	    top: 100%;
	    width: 100%;
	    left: 0px;
    }

    .signInLink::after
    {
	    border-left: 2px solid rgba(255,255,255,.8);
	    border-right: 2px solid rgba(255,255,255,.8);
    }

    .signInLink::before
    {
	    background-color: rgba(0,0,0,0.5);
	    border-bottom: 2px solid #272727;
    }*/
    .signInLink h4,
    .signInLink hr {
        display: none;
    }

    .signInLink .small {
        font-size: 13px;
    }

    .signInLink .dropdown-menu a {
        width: auto;
        display: inherit;
    }

    .slide-up {
        position: absolute;
        height: 200px;
        bottom: -200px;
        width: 90%;
        left: 5%;
        transition: bottom 0.6s;
        background-color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        z-index: 100;
    }

    .slide-up:hover {
        bottom: 0px;
        z-index: 1000;
    }

    .slide-up-content {
        padding: 5px;
        color: #333;
    }

    .slide-up .tab {
        position: absolute;
        top: -70px;
        left: 51%;
    }

    .slide-up .tab img {
        width: 64px !important;
    }

    .slide-up:last-of-type .tab {
        right: 51%;
        left: auto;
    }

    .terms-frame {
        border: 1px solid #ccc;
        border-radius: 2px;
        margin: 10px 0px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
    /*#termsHolder label::before
    {
	    margin-right: 5px;
    }*/
    div.validation-summary-errors ul li {
        padding-left: 120px;
        position: relative;
        margin-bottom: 10px;
        list-style-type: none;
    }

    div.validation-summary-errors ul li:last-of-type {
        min-height: 56px;
    }

    div.validation-summary-errors ul li:first-of-type::before,
    .form-group span.field-validation-error::before {
        font-family: 'Font Awesome 5 Free', FontAwesome;
        content: '\f071';
        font-size: 1.3em;
        margin-right: 5px;
    }

    div.validation-summary-errors ul li:first-of-type::before {
        font-size: 48px;
        position: absolute;
        left: 50px;
        top: -10px;
    }

    .table-striped tr > th:last-of-type,
    .table-striped tr > td:last-of-type {
        text-align: right;
    }

    .iao-metadata-table tr > th:last-of-type,
    .iao-metadata-table tr > td:last-of-type {
        text-align: left;
    }

    @media only screen and (max-width: 1230px) {
        #authStart.big-btns .btn-holder .default-btn {
            height: 120px;
        }
    }

    @media only screen and (min-width: 601px) and (max-width: 999px) {
        .bannerCentral img.scale-1 {
            width: 100px;
            right: 185px;
        }

        .bannerCentral img.scale-2 {
            width: 120px;
            right: 265px;
        }

        .bannerCentral img.scale-3 {
            width: 270px;
        }

        .bannerCentral .esri-partner-logo {
            right: 395px;
        }

        .bannerCentral .esri-partner-logo img {
            width: 24px;
        }

        .bannerCentral .auto-width-inv-r {
            margin-right: 275px;
        }
    }

    @media only screen and (max-width: 750px) {
        #authStart.big-btns .btn-holder > div {
            width: 100%;
            height: auto;
            display: block;
            margin-left: 0px;
            margin-right: 0px;
        }

        #authStart.big-btns .btn-holder .default-btn {
            height: auto;
        }
    }

    input[type='checkbox']:not(.native),
    input[type='radio'] {
        visibility: visible;
        opacity: 0;
    }

    input:focus ~ label {
        border-color: #66afe9;
        outline: 0;
        /*padding: 5px 5px 5px 0px;*/
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }

    @media only screen and (max-width: 1025px) {
        .simple-central {
            width: 90%;
        }

        .form-video-embed iframe {
            max-width: 100%;
        }
    }
    /*@media screen and (max-width: 1010px) {
        .big-btns > .btn-holder > div {
            width: 200px;
        }

        .big-btns > .btn-holder > div > .btn .fa {
            display: block;
        }

        .big-btns > .btn-holder > div > .btn {
            display: block;
            width: 200px;
            max-width: 200px;
            min-width: 200px;
            font-size: 28px;
        }

        .signInLink .dropdown-menu {
            width: 626px;
        }

        .signInLink .dropdown-menu .btn {
            font-size: 28px;
        }

        .signInLink::after, .signInLink::before {
            height: 12px;
        }
    }*/
    @media screen and (max-width: 830px) {
        .iao-wave {
            margin-top: 5px;
            margin-bottom: 1px;
        }

        .iao-wave .ia-name {
            width: 70px;
            max-width: 70px;
            min-width: 70px;
            height: 70px;
        }

        .iao-wave .app-name .product-name {
            font-size: 0.5em;
        }

        .iao-wave .app-name .builder-name {
            font-size: 1.4em;
        }
        /*.big-btns > .btn-holder > div {
            width: 170px;
        }

        .big-btns > .btn-holder > div > .btn .fa {
            display: block;
        }

        .big-btns > .btn-holder > div > .btn {
            display: inline-block;
            width: 170px;
            max-width: 170px;
            min-width: 170px;
            font-size: 24px;
        }*/
        .signInLink .dropdown-menu {
            width: 538px;
        }

        .signInLink .dropdown-menu .btn {
            font-size: 24px;
        }

        .signInLink::after,
        .signInLink::before {
            height: 11px;
        }

        .signInLink > .dropdown-menu {
            font-size: 16px;
        }

        .form-video-embed {
            top: auto;
            bottom: 19em;
        }

        .form-video-embed iframe {
            max-width: 300px;
            max-height: 200px;
        }

        .col-md-10 h1 {
            width: 60%;
        }
    }

    @media screen and (max-width: 730px) {
        .big-btns .btn-holder,
        .bannerCentral > .pad10 {
            margin: 0px;
            padding: 0px;
        }
        /*.big-btns > .btn-holder > div {
            width: 130px;
        }

        .big-btns > .btn-holder > div > .btn {
            display: inline-block;
            width: 130px;
            max-width: 130px;
            min-width: 130px;
            font-size: 13px;
        }

        .big-btns > .btn-holder > div > .btn .fa {
            font-size: 32px;
        }*/
        .signInLink .dropdown-menu {
            width: 418px;
        }

        .signInLink .dropdown-menu .btn {
            font-size: 18px;
        }

        .signInLink::after,
        .signInLink::before {
            height: 10px;
        }

        .signInLink > .dropdown-menu {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 550px) {
        /*.big-btns > .btn-holder {
            width: 420px;
        }

        .big-btns > .btn-holder > div {
            width: 100px;
        }

        .big-btns > .btn-holder > div > .btn .fa {
            display: block;
        }

        .big-btns > .btn-holder > div > .btn {
            display: inline-block;
            width: 100px;
            max-width: 100px;
            min-width: 100px;
            height: 100px;
            white-space: normal;
        }*/
        .signInLink .dropdown-menu {
            width: 325px;
        }

        .signInLink::after,
        .signInLink::before {
            height: 13px;
        }
    }
    /* Temporary overrides for Font Awesome 5 - these will become part of the core defaults.css/.less */
    input[type='checkbox']:not(.native) + label::before,
    input[type='radio'] + label::before,
    .checkbox input[type='checkbox']:not(.native) ~ label::before,
    .checkbox input[type='radio'] ~ label::before {
        content: '\f0c8';
        margin-top: -1px;
    }

    input[type='checkbox']:not(.native):checked + label::before,
    .checkbox input[type='checkbox']:not(.native):checked ~ label::before {
        content: '\f14a';
    }

    .geo-list li::before {
        font-family: 'Font Awesome 5 Free', FontAwesome;
        content: '\f57c';
        font-weight: 900;
        margin-right: 5px;
        font-size: 1.25em;
        position: absolute;
        left: -1.2em;
        top: -0.2em;
        transform: rotate(23.5deg);
    }

    .esriSimpleSliderIncrementButton,
    .esriSimpleSliderDecrementButton {
        height: 38px;
    }

    .esriSimpleSliderIncrementButton::after {
        content: '\f055';
        font-weight: 900;
    }

    .esriSimpleSliderDecrementButton::after {
        content: '\f056';
        font-weight: 900;
    }
    /* Home page experiments */
    &.anon #anonStart {
        margin-bottom: 22vh;
        margin-top: 12vh;
    }

    .landing {
        footer {
            position: fixed;
            bottom: 2px;
            left: 5vw;
            width: 90vw;
        }

        .legacy-sign-in-link {
            position: fixed;
            bottom: 2px;
            left: calc(6vw + 30em);
            width: 40vw;
            max-width: 48vw;
            color: #666;
            font-size: 0.85em;
            opacity: 0.9;
        }
    }

    .product-buttons {
        z-index: 1008;

        .popout-content {
            position: fixed;
            right: -120vw;
            top: 35vh;
            width: 50vw;
            height: auto;
            min-height: 30vh;
            margin-top: -5vh;
            background-color: rgba(255, 255, 255, 0.95);
            color: #333;
            padding: 20px;
            border: 2px solid #fff;
            border-radius: 2px;
            transition: right 0.5s;

            &.in {
                display: block;
                right: 25vw;
            }
        }

        .hub-wrapper {
            font-size: 1em;
            width: 26em;
            height: 26em;
            overflow: hidden;
            position: fixed;
            z-index: 10;
            bottom: 80vh;
            right: 5vw;
            border-radius: 50%;
            margin-bottom: -13em;
            margin-right: -13em;
            transform: scale(0.1);
            transition: all 0.3s ease;

            li {
                position: absolute;
                font-size: 1.5em;
                width: 10em;
                height: 10em;
                transform-origin: 100% 100%;
                overflow: hidden;
                left: 50%;
                top: 50%;
                margin-top: -1.3em;
                margin-left: -10em;
                transition: border 0.3s ease;

                a {
                    display: block;
                    font-size: 1.18em;
                    height: 14.5em;
                    width: 14.5em;
                    position: absolute;
                    bottom: -7.25em;
                    right: -7.25em;
                    border-radius: 50%;
                    text-decoration: none;
                    color: #fff;
                    padding-top: 1.8em;
                    text-align: center;
                    transform: skew(-30deg) rotate(-60deg) scale(1);
                    transition: opacity 0.3s, color 0.3s;

                    span {
                        display: block;
                        font-size: 1.1em;
                        opacity: 0.7;
                    }

                    > img {
                        display: inline-block;
                        width: 2.5em;
                        height: auto;
                    }
                }

                span {
                    i.fas,
                    i.far {
                        font-size: 2em;
                    }
                }
            }

            li:first-child {
                transform: rotate(-30deg) skew(30deg);

                span,
                img {
                    transform: rotate(150deg) translate(0, 10px);
                }
            }

            li:nth-child(2) {
                transform: rotate(-30deg) skew(30deg);

                span,
                img {
                    transform: translate(5.7em, -0.4em) rotate(90deg); /*rotate(90deg) translate(-10px, 0);*/
                }
            }

            li:nth-child(3) {
                transform: rotate(-30deg) skew(30deg);

                span,
                img {
                    transform: rotate(30deg) translate(5em, -3.5em); /*rotate(30deg) translate(0em, -.2em);*/
                    display: block;
                }
            }

            li:nth-child(4) {
                transform: rotate(90deg) skew(30deg);
            }

            li:nth-child(5) {
                transform: rotate(150deg) skew(30deg);
            }

            li:nth-child(6) {
                transform: rotate(210deg) skew(30deg);
            }

            li:nth-child(odd) a {
                background-color: var(--ia-app-hi-color);
            }

            li:nth-child(even) a {
                background-color: var(--ia-app-hi-color-light);
            }

            &.right {
                right: 0;
            }
        }

        .open {
            > .hub-wrapper {
                border-radius: 50%;
                transform: scale(1);

                li:first-child {
                    animation: hub-segment-down 0.15s ease 0.2s normal 1 forwards;
                }

                li:nth-child(3) {
                    animation: hub-segment-up 0.15s ease 0.3s normal 1 forwards;
                }
            }
        }

        .hub-button {
            border: none;
            background: none;
            /* color: white; */
            text-align: left;
            font-size: 1.5em;
            height: 2.5em;
            width: 2.75em;
            background-color: #ccc;
            position: fixed;
            right: 5vw;
            margin-right: -1.25em;
            margin-bottom: -1.25em;
            bottom: 80vh;
            border-radius: 50%;
            cursor: pointer;
            z-index: 11;
            padding-left: 0.4em;
            clip-path: inset(0 1.25em 0 0);
            line-height: 1.5em;
            padding-top: 0.2em;

            &.right {
                right: 0;
            }
        }

        &.product-buttons-sm {
            .hub-wrapper {
                font-size: 0.75em;
                width: 19.5em;
                height: 19.5em;
                margin-bottom: -9.75em;
                margin-right: -9.75em;

                li {
                    font-size: 1.125em;
                    width: 9.75em;
                    height: 9.75em;
                    margin-top: -1.125em;
                    margin-left: -9.75em;
                    /*a {
                        height: 10.875em;
                        width: 10.875em;
                        bottom: -5.4375em;
                        right: -5.4375em;
                    }*/
                }

                li:nth-child(2) {
                    span,
                    img {
                        transform: rotate(90deg) translate(-10px, 0);
                    }
                }

                li:nth-child(3) {
                    span,
                    img {
                        transform: rotate(30deg) translate(0em, -0.2em);
                        display: block;
                    }
                }
            }

            .hub-button {
                font-size: 1em;
                height: 1.5em;
                width: 2.06em;
                background-color: #ccc;
                position: fixed;
                right: 5vw;
                margin-right: -1.03em;
                margin-bottom: -0.75em;
                clip-path: inset(0 0.95em 0 0);
                padding: 0.1em 0 0 0.2em;

                > i.fas {
                    position: absolute;
                    top: calc(50% - 0.5em);
                    display: block;
                    left: 0.3em;
                }
            }
        }

        &.right {
            .hub-wrapper,
            .hub-button {
                right: 0;
            }
        }
    }

    .hover-grow {
        display: flex;
        align-items: center;

        > span,
        > div,
        > img {
            flex-grow: 1;
            transition: transform 0.15s linear;
        }

        &:hover {
            > span,
            > div,
            > img {
                transform: scale(1.025);
            }
        }
    }

    .esri-identity-modal.esri-identity-modal--open {
        z-index: 10001;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        .esri-identity-modal__title {
            margin: -20px -22px 10px -22px;
            padding: 20px 20px;
            background-color: #333;
            color: #fff;
        }
        .esri-identity-modal__close-button {
            color: #fff;
        }
        .esri-identity-form__group {
            label,
            input[type='text'],
            input[type='password'] {
                display: block;
                width: 100%;
            }
        }
        .esri-button {
            margin-right: 5px;
            border-color: var(--ia-app-hi-color-dark);
            background-color: var(--ia-app-hi-color);
            color: var(--ia-app-body-color);
            font-weight: bold;
            &:last-child {
                margin-right: 0;
            }
            &.esri-button--secondary {
                background-color: transparent;
                color: var(--ia-app-body-color);
            }
        }
    }
}

@keyframes hub-segment-up {
    0% {
        transform: rotate(-30deg) skew(30deg);
    }

    100% {
        transform: rotate(30deg) skew(30deg);
    }
}

@keyframes hub-segment-down {
    0% {
        transform: rotate(-30deg) skew(30deg);
    }

    100% {
        transform: rotate(-90deg) skew(30deg);
    }
}
